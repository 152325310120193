const funcUrl = funcName => {
    return replaceAllDoubleSlashButHttpsInUrl(
        `${process.env.REACT_APP_FUNCTIONS_PREFIX}/${funcName}`);
};

function replaceAllDoubleSlashButHttpsInUrl(url) {
    return url.replace(/([^:]\/)\/+/g, '$1');
}

const firebaseFunc = funcUrl;
export {
    funcUrl,
    firebaseFunc,
};
