import React from "react";

export class AddButton extends React.Component<{ text?: any, onClick: any, className?: string }> {
    render() {
        let {text, onClick, className} = this.props;
        return (
            <div className={`w-full h-24 p-2 pt-0 bg-white`}>
                <div
                    onClick={onClick}
                    className={`rounded-lg border-dashed border-[2px] border-dark-blue flex w-full h-full hover:bg-gray-50 cursor-pointer ${className ?? ''}`}>
                    <img className={`m-auto`} src={`/images/icons/iconAdd.svg`} alt=""/>
                </div>
            </div>
            // <button
            //     className={`text-white cursor-pointer hover:bg-green-600 bg-green-400 border-none w-full p-2 ${className ?? ''}`}
            //     onClick={onClick}
            // >
            //     {text || '＋'}
            // </button>
        )
    }
}
