import {firestore, storage} from "firebase";
import React, {RefObject, useEffect, useState} from "react";
import {BANNER_FOLDER, POPUP_FOLDER} from "../../constants/folder";
import {bannersRef, imgFireStore, popupsRef} from "../../firebase/firestore";
import {imageStorage} from "../../firebase/store";
import {
    BuilderBannerPopupSelector,
    ImageBuilderImageItem,
    ImageBuilderImageList,
    ImageBuilderUploader,
} from "../ScenarioPage/Builders";
import {createObjectURL} from "../ScenarioPage/ScenarioPage";
import uniqid from "uniqid";
import {useModal} from "../../hooks/useModal";
import DeleteModal from "../../component/DeleteModal";
import toast from "react-hot-toast";

const BannerPopup = () => {
    const [bannerImgs, setBannerImgs] = useState<any>([]);
    const [popupImgs, setPopupImgs] = useState<any>([]);
    const [banners, setBanners] = useState<any>([]);
    const [popups, setPopups] = useState<any>([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const {isDeleteModal, toggleDeleteModal} = useModal();
    const [deleteImg, setDeleteImg] = useState<any>({});

    useEffect(() => {
        const unsubBannerObserver = bannersRef.onSnapshot((snapshot) => {
            const banners = snapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }));
            setBanners(banners);
        });
        const unsubPopupObserver = popupsRef.onSnapshot((snapshot) => {
            const popups = snapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }));
            setPopups(popups);
        });
        return () => {
            unsubBannerObserver();
            unsubPopupObserver();
        };
    }, []);
    const onUploadBannerClick = async (e, textInputRef) => {
        if (isUploading) {
            return;
        }
        await handleUploadImgs(bannerImgs, bannersRef, textInputRef, BANNER_FOLDER);
        setBannerImgs([]);
    };
    const onUploadPopupClick = async (e, textInputRef) => {
        if (isUploading) {
            return;
        }
        await handleUploadImgs(popupImgs, popupsRef, textInputRef, POPUP_FOLDER);
        setPopupImgs([]);
    };

    const handleUploadImgs = async (
        imgs: [],
        ref: firestore.CollectionReference<firestore.DocumentData>,
        textInputRef: RefObject<HTMLInputElement | null>,
        folder: string
    ) => {
        try {
            const uploadToStoragePromises: Promise<storage.UploadTaskSnapshot>[] = [];
            const uploadToStorePromises: Promise<void>[] = [];

            imgs.forEach((img: any) => {
                uploadToStoragePromises.push(
                    imageStorage.putFile(uniqid("image_"), img.raw, folder)
                );
            });
            setIsUploading(true);
            const uploadTasks = await Promise.all(uploadToStoragePromises);
            const uploadImgPromises = uploadTasks.map(async (task) => {
                const url = await task.ref.getDownloadURL();
                const name = task.ref.name;
                const isShow = false
                return {name, url, isShow, pageUrl: textInputRef.current?.value || ""};
            });

            const uploadImgs = await Promise.all(uploadImgPromises);
            uploadImgs.forEach((img) => {
                uploadToStorePromises.push(
                    imgFireStore.putFile(img.name, img.url, img.pageUrl, ref)
                );
            });
            await Promise.all(uploadToStorePromises);
            setIsUploading(false);
        } catch (err) {
            console.error(err);
            setIsUploading(false);
        }
    };

    const handleChangeImg = (e: any, imgs: [], setImgs: React.Dispatch<any>) => {
        const files = e.target.files;
        if (files.length > 0) {
            const fileArr = [];
            fileArr.push.apply(fileArr, files);
            const newImgs = fileArr.map((file) => ({
                raw: file,
                url: createObjectURL(file),
            }));
            setImgs([...imgs, ...newImgs]);
        }
    };

    function changeTabIndex(e: any, newValue: number) {
        setTabIndex(newValue);
    }

    function editImage(
        textInputRef: RefObject<HTMLInputElement | null>,
        pageUrl: string
    ) {
        if (textInputRef.current !== null) {
            textInputRef.current.focus();
            textInputRef.current.value = pageUrl || "";
        }
    }

    function applyImage(
        id: string,
        ref: firestore.CollectionReference<firestore.DocumentData>,
        textInputRef: RefObject<HTMLInputElement | null>
    ) {
        const pageUrl = textInputRef.current?.value || "";
        imgFireStore.updateFile(id, ref, pageUrl);
    }

    function onDeleteImg(
        id: string,
        name: string,
        ref: firestore.CollectionReference<firestore.DocumentData>,
        folder: string
    ) {
        imgFireStore
            .delFile(id, ref)
            .then(() => {
                imageStorage.delFile(name, folder);
            })
            .then(() => {
                toast.success(`削除しました`);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    function setIsShowImage(
        id: string,
        ref: firestore.CollectionReference<firestore.DocumentData>,
        isShow: Boolean
    ) {
        imgFireStore.updateIsShowFile(id, ref, isShow)
    }

    return (
        <>
            <BuilderBannerPopupSelector
                value={tabIndex}
                onChange={changeTabIndex}
            ></BuilderBannerPopupSelector>
            <DeleteModal
                title={`削除しますか?`}
                show={isDeleteModal}
                setShow={toggleDeleteModal}
                onYes={() => {
                    onDeleteImg(
                        deleteImg?.id,
                        deleteImg?.name,
                        deleteImg?.ref,
                        deleteImg?.folder
                    );
                    toggleDeleteModal(false);
                }}
                onNo={() => {
                    toggleDeleteModal(false);
                }}
            ></DeleteModal>
            {tabIndex === 0 && (
                <div>
                    <ImageBuilderUploader
                        onChange={(e) => handleChangeImg(e, bannerImgs, setBannerImgs)}
                        uploadImages={bannerImgs}
                        isUploading={isUploading}
                        isInputExist
                        onClick={onUploadBannerClick}
                    ></ImageBuilderUploader>
                    <ImageBuilderImageList
                        images={banners}
                        element={(image: any, idx: number) => {
                            return (
                                <>
                                    <ImageBuilderImageItem
                                        key={image.id}
                                        image={image}
                                        isBannerPopup
                                        onClick={(e, textInputRef) =>
                                            editImage(textInputRef, image.pageUrl)
                                        }
                                        onApply={(e, textInputRef) =>
                                            applyImage(image.id, bannersRef, textInputRef)
                                        }
                                        onShowModal={() => {
                                            setDeleteImg({
                                                id: image.id,
                                                name: image.name,
                                                ref: bannersRef,
                                                folder: BANNER_FOLDER,
                                            });
                                            toggleDeleteModal(true);
                                        }}
                                        onShowClick={() => {
                                            setIsShowImage(image.id, bannersRef, image.isShow)
                                        }}
                                    />
                                </>
                            );
                        }}
                    />
                </div>
            )}
            {tabIndex === 1 && (
                <div>
                    <ImageBuilderUploader
                        onChange={(e) => handleChangeImg(e, popupImgs, setPopupImgs)}
                        uploadImages={popupImgs}
                        isUploading={isUploading}
                        isInputExist
                        onClick={onUploadPopupClick}
                    ></ImageBuilderUploader>
                    <ImageBuilderImageList
                        images={popups}
                        element={(image: any, idx: number) => {
                            return (
                                <>
                                    <ImageBuilderImageItem
                                        key={image.id}
                                        image={image}
                                        isBannerPopup
                                        onClick={(e, textInputRef) =>
                                            editImage(textInputRef, image.pageUrl)
                                        }
                                        onApply={(e, textInputRef) =>
                                            applyImage(image.id, popupsRef, textInputRef)
                                        }
                                        onShowModal={() => {
                                            setDeleteImg({
                                                id: image.id,
                                                name: image.name,
                                                ref: popupsRef,
                                                folder: POPUP_FOLDER,
                                            });
                                            toggleDeleteModal(true);
                                        }}
                                        onShowClick={() => {
                                            setIsShowImage(image.id, popupsRef, image.isShow)
                                        }}
                                    />
                                </>
                            );
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default BannerPopup;
