import {atom} from 'jotai';
import {first} from 'lodash/array';
import _get from 'lodash/get';

const scenarioListAtom = atom([]);

const firstScenarioFileNameAtom = atom((get) => {
    const scs = get(scenarioListAtom);
    return _get(first(scs), 'filename');
});
export {
    scenarioListAtom,
    firstScenarioFileNameAtom,
};
