import React, {useEffect, useRef, useState} from "react";
import {Grid, MenuItem} from "@material-ui/core";
import axios from "axios";
import {imageStorage, scenarioStore} from "../../firebase/store";
import Store from "../../Store/CommonStore";
import {DataStorage, DataUnit} from "../../model/DataStructure";
import {
    ChoiceItem,
    DelayItem,
    ForEndItem,
    ForStartItem,
    IfEndItem,
    IfStartItem,
    ImageItem,
    InputItem,
    MotionItem,
    ScenarioContentBuilder,
    SlideItem,
    TextItem,
} from "./Items";
import {
    AutoSaveCheckbox,
    DataStructureView,
    DeployButton,
    LanguageSelector,
    MentaroidView,
    MetadataView,
    ScenarioSelector,
} from "./Views";
import {
    BuilderSelector,
    ControlBuilder,
    ImageBuilderImageItem,
    ImageBuilderImageList,
    ImageBuilderUploader,
    InputBuilder,
    MotionBuilder,
    SlideBuilder,
    TextBuilderList,
} from "./Builders";
import {useAtom} from "jotai";
import {lastScenarioAtom} from "../../atoms/lastScenario";
import {firebaseFunc} from "../../utils/functions";
import {scenarioListAtom} from "../../atoms/scenarioList.atom";
import {storage} from "firebase";
import {IMAGES, THUMB} from "../../constants/folder";
import uniqid from "uniqid";
import {v4 as uuidv4} from 'uuid';

export const createObjectURL = (window.URL || window.webkitURL).createObjectURL;

// a little function to help us with reordering the result
export const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;
export let autoSaveFlag = false;

export const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "#FDF" : "white",
    position: "relative",
    // styles we need to apply on draggables
    ...draggableStyle,
});

export const getListStyle = (isDraggingOver: boolean) => ({
    //background: isDraggingOver ? "lightblue" : "#EEE",
    padding: grid,
});

export interface ItemArg {
    name: string;
    target_id: string;
    rank?: number;
    groups?: string[];
}

export const getArgs = (str) => {
    let args = [] as ItemArg[];
    if (!str) return args;
    const found = str.match(/\{.*?\}/g);
    if (found) {
        found.forEach((x) => {
            args.push({name: x, target_id: ""});
        });
    }
    return args;
};

export class ScenarioContext {
}

export const Enecolor4Color: string[] = ["R", "Y", "G", "B"];
export const Enecolor16Color: string[] = [
    "ROS",
    "RCS",
    "RCG",
    "ROG",
    "YOS",
    "YCS",
    "YCG",
    "YOG",
    "GOS",
    "GCS",
    "GCG",
    "GOG",
    "BOS",
    "BCS",
    "BCG",
    "BOG",
];

export function GenUnits(store) {
    const storages = store.get("storages") as DataStorage[];
    const checkedStorage = store.get("checkedStorage");
    const units = [] as { unit: DataUnit; storage: DataStorage }[];
    if (!storages || !checkedStorage) return [];
    for (let key in checkedStorage) {
        const o = storages.find((x) => x.name == key);
        if (o) {
            o.data.forEach((unit) => {
                units.push({unit: unit, storage: o});
            });
        }
    }
    return units;
}

function genRandomName(len = 16) {
    let l = len;
    let c = "abcdefghijklmnopqrstuvwxyz0123456789";
    let cl = c.length;
    let r = "";
    for (let i = 0; i < l; i++) {
        r += c[Math.floor(Math.random() * cl)];
    }
    return r;
}

export interface LangDBData {
    idx: number;
    category: string;
    comment: string;
    order: string;
    japanese: string;
    english: string;
}

export interface EnecolorGroupData {
    category: string;
    comment: string;
    messages: string[];
}

const defaultUseMentoroid = {ena: true, rabic: false};

type Image = {
    file?: File,
    url: string,
}

export const defPageSize = 5;
export const pageSizeKey = 'textInputPageSize';
const initialImage = {file: undefined, url: ""}

export default function ScenarioPage() {
    const [langData, setLangData] = useState([] as LangDBData[]);
    const [enecolorGroupData, setEnecolorGroupData] = useState(
        [] as EnecolorGroupData[]
    );
    const [scenario, setScenario] = useState([]);
    const [title, setTitle] = useState("");
    const [cover, setCover] = useState("");
    const [explain, setExplain] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [isDevActive, setIsDevActive] = useState(false);
    const [isTool, setIsTool] = useState(false);
    const [isOnlyImage, setIsOnlyImage] = useState(false);
    const [chapterIndex, setChapterIndex] = useState(0);
    const [chapterTitle, setChapterTitle] = useState("");
    const [chapterSubTitle, setChapterSubTitle] = useState("");
    const [chapterLink, setChapterLink] = useState<any>("");
    const [image, setImage] = useState<Image>(initialImage)
    const [newFilename, setNewFileName] = useState<string>('');
    const [filename, setFilename] = useState("");
    const [scenarioList, setScenarioList] = useAtom(scenarioListAtom);
    const [tabIndex, setTabIndex] = useState(0);
    const [uploadImages, setUploadImages] = useState<any>([]);
    const [images, setImages] = useState<any>([]);
    const [lang, setLang] = useState("japanese");
    const [deployMessage, setDeployMessage] = useState("");
    const [autoSaveMessage, setAutoSaveMessage] = useState("");
    const [usedMentoroid, setUsedMentoroid] = useState(defaultUseMentoroid);
    const [lastScenario, setLastScenario] = useAtom(lastScenarioAtom);
    const store = Store.useStore();
    const [pageSize, setPageSize] = useState<number>(defPageSize);

    useEffect(() => {
        const _pageSize = Number(localStorage.getItem(pageSizeKey) ?? defPageSize)
        setPageSize(_pageSize)
    }, [])

    useEffect(() => {
        const rngName = uuidv4()
        setNewFileName(rngName)
    }, [])

    // useEffect(() => {
    //     console.log(scenarioList)
    // }, [scenarioList])

    function loadLangDB() {
        axios.get(firebaseFunc("load_lang_db")).then((res) => {
            //axios.get("http://localhost:5000/").then(res => {
            let data = res.data as LangDBData[];
            const groups = enecolorGroupData;
            data = data?.sort((a, b) => {
                const ao = parseInt(a.order);
                const bo = parseInt(b.order);
                if (ao > bo) return 1;
                if (ao < bo) return -1;
                return 0;
            });
            data?.forEach((x) => {
                if (x.comment != null && x.comment != "") {
                    const com = x.comment;
                    const cat = x.category;
                    const gidx = groups.findIndex(
                        (g) => g.category == cat && g.comment == com
                    );
                    if (gidx >= 0) {
                        groups[gidx].messages.push(x.japanese);
                    } else {
                        groups.push({
                            category: cat,
                            comment: com,
                            messages: [x.japanese],
                        });
                    }
                }
            });
            setEnecolorGroupData(groups);
            setLangData(res.data);
        });
    }

    function loadScenarios(clbFn?: Function) {
        scenarioStore
            .listScenario()
            .then((metas) => {
                if (!metas) return;
                setScenarioList(
                    metas
                        .filter((s) => s.name !== "list.json")
                        .map((s) => {
                            return {
                                filename: s.name,
                                title: s.customMetadata ? s.customMetadata.title : "err",
                                cover: s.customMetadata ? s.customMetadata.cover : "",
                                chapterIndex: s.customMetadata
                                    ? s.customMetadata.chapterIndex
                                    : "0",
                                chapterTitle: s.customMetadata
                                    ? s.customMetadata.chapterTitle
                                    : "",
                                chapterSubTitle: s.customMetadata
                                    ? s.customMetadata.chapterSubTitle
                                        ? s.customMetadata.chapterSubTitle
                                        : ""
                                    : "",
                                chapterLink: s.customMetadata
                                    ? s.customMetadata.chapterLink
                                        ? s.customMetadata.chapterLink
                                        : ""
                                    : "",
                                chapterThumb: s.customMetadata
                                    ? s.customMetadata.chapterThumb
                                        ? s.customMetadata.chapterThumb
                                        : ""
                                    : "",
                                isActive: s.customMetadata
                                    ? s.customMetadata.isActive == "true"
                                    : false,
                                isTool: s.customMetadata
                                    ? s.customMetadata.isTool == "true"
                                    : false,
                                isDevActive: s.customMetadata
                                    ? s.customMetadata.isDevActive == "true"
                                    : false,
                                explain: s.customMetadata
                                    ? s.customMetadata.explain
                                        ? s.customMetadata.explain
                                        : ""
                                    : "",
                                usedMentoroid: s.customMetadata
                                    ? s.customMetadata.usedMentoroid
                                    : {ena: true, rabic: false},
                            };
                        })
                        .sort((a, b) => {
                            if (a.title != b.title) return a.title < b.title ? -1 : 1;
                            return a.chapterIndex < b.chapterIndex ? -1 : 1;
                        }) as any
                );
                clbFn?.();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function loadScenarioFile(name: string) {
        if (name === newFilename) {
            setFilename(name);
            setScenario([]);
            setTitle("");
            setCover("");
            setChapterIndex(0);
            setChapterTitle("");
            setChapterSubTitle("");
            setChapterLink("");
            setImage(initialImage);
            setIsActive(false);
            setIsDevActive(false);
            setIsTool(false);
            setIsOnlyImage(false);
            setExplain("");
            setUsedMentoroid(defaultUseMentoroid);
            setPageSize(defPageSize)
        } else {
            scenarioStore
                .getFile(name)
                .then((x) => {
                    console.log(x);
                    setScenario(x.data.data);
                    setFilename(name);
                    setTitle(x.data.title);
                    setCover(x.data.cover || "");
                    setChapterIndex(x.data.chapterIndex);
                    setChapterTitle(x.data.chapterTitle);
                    setChapterSubTitle(
                        x.data.chapterSubTitle ? x.data.chapterSubTitle : ""
                    );
                    setChapterLink(x.data.chapterLink ? x.data.chapterLink : "")
                    setImage({...initialImage, url: x.data.chapterThumb ? x.data.chapterThumb : ""})
                    setIsActive(x.data.isActive === true);
                    setIsDevActive(x.data.isDevActive === true);
                    setIsTool(x.data.isTool === true);
                    setIsOnlyImage(x.data.isOnlyImage === true);
                    setExplain(x.data.explain ? x.data.explain : "");
                    setUsedMentoroid(
                        x.data.usedMentoroid ? x.data.usedMentoroid : defaultUseMentoroid
                    );
                    if (x.data.checkedStorage) {
                        store.set("checkedStorage")(x.data.checkedStorage);
                    } else {
                        store.set("checkedStorage")({});
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        autoSaveFlag = true;
        setAutoSaveMessage("自動保存有効");
    }

    function loadImages() {
        imageStorage
            .getAllFile(IMAGES)
            .then((res) => {
                const imgPromise = res.items.map(async (x) => {
                    const url = await imageStorage.getFileUrl(x.name, IMAGES);
                    return {name: x.name, url: url};
                });
                Promise.all(imgPromise).then((x) => {
                    setImages([{name: "", url: ""}].concat(x) as any);
                });
            })
            .catch((error) => console.error(error));
    }

    async function loadAndDelThumbs() {
        const res = await imageStorage.getAllFile(THUMB + "/" + filename)
        const nameList = res.items.map(x => x.name);
        const deletePromises = nameList.map(x => imageStorage.delFile(x, THUMB + "/" + filename))
        await Promise.all(deletePromises);
    }

    const callbackRef = useRef<() => Promise<void>>(autoSave);
    useEffect(() => {
        callbackRef.current = autoSave; // 新しいcallbackをrefに格納！
    }, [autoSave]);

    useEffect(() => {
        const tick = async () => {
            await callbackRef.current();
        };
        const id = setInterval(async () => {
            await tick()
        }, 180000);
        return () => {
            clearInterval(id);
        };
    }, []); //refはミュータブルなので依存配列に含めなくてもよい

    useEffect(() => {
        loadLangDB();
        loadImages();

        const clb = () => {
            setFilename(lastScenario);
            loadScenarioFile(lastScenario);
        };

        loadScenarios(clb);
    }, [lastScenario]);

    async function autoSave() {
        if (!autoSaveFlag) {
            setAutoSaveMessage("自動保存停止中");
            return;
        }
        const date1 = new Date();
        await saveScript(true);
        setAutoSaveMessage("自動保存中（前回:" + date1.toLocaleString() + ")");
    }

    async function saveScript(isAutoSave: boolean) {
        try {
            let url: string = image.url;
            if (image.file && !isAutoSave) {
                await loadAndDelThumbs();
                const imageSnapshot = await imageStorage.putFile(uniqid("image_"), image.file, THUMB + "/" + filename);
                url = await imageSnapshot.ref.getDownloadURL();
            }

            let obj = {
                title: title,
                cover: cover,
                data: scenario,
                chapterIndex: chapterIndex,
                chapterTitle: chapterTitle,
                chapterSubTitle: chapterSubTitle,
                chapterLink: chapterLink,
                chapterThumb: url,
                isActive: isActive,
                isTool: isTool,
                isOnlyImage: isOnlyImage,
                isDevActive: isDevActive,
                explain: explain,
                checkedStorage: store.get("checkedStorage"),
                usedMentoroid: usedMentoroid,
            };

            let metadata = {
                customMetadata: {
                    title: title,
                    cover: cover,
                    chapterIndex: chapterIndex,
                    chapterTitle: chapterTitle,
                    chapterSubTitle: chapterSubTitle,
                    chapterLink: chapterLink,
                    chapterThumb: url,
                    isActive: isActive,
                    isTool: isTool,
                    isOnlyImage: isOnlyImage,
                    isDevActive: isDevActive,
                    explain: explain,
                },
            };
            setDeployMessage("送信中");
            await scenarioStore.putFile(filename, JSON.stringify(obj), metadata)
            scenarioStore.updateListJSON();
            //setNewFilename(genRandomName())
            loadScenarios();
            //setFilename(filename);
            setDeployMessage("完了");
        } catch (error) {
            setDeployMessage("エラー");
            console.error(error);
        }
    }

    function addText(langItem: any) {
        setScenario([...scenario, new TextItem("text", langItem)] as any);
    }

    function addImage(name: string, url: string) {
        setScenario([...scenario, new ImageItem(name, url)] as any);
    }

    function addInput() {
        setScenario([...scenario, new InputItem("string")] as any);
    }

    function addInputSlider() {
        setScenario([...scenario, new InputItem("number")] as any);
    }

    function addChoice() {
        setScenario([...scenario, new ChoiceItem()] as any);
    }

    function addIf() {
        setScenario([...scenario, new IfStartItem(), new IfEndItem()] as any);
    }

    function addMotion() {
        setScenario([...scenario, new MotionItem()] as any);
    }

    function addSlide() {
        setScenario([...scenario, new SlideItem()] as any);
    }

    function addFor() {
        setScenario([...scenario, new ForStartItem(), new ForEndItem()] as any);
    }

    function addDelay() {
        setScenario([...scenario, new DelayItem()] as any);
    }

    function changeTabIndex(e: any, newValue: number) {
        setTabIndex(newValue);
    }

    function changeUploadImage(e: any) {
        const files = e.target.files;
        if (files.length > 0) {
            const fileArr = [];
            fileArr.push.apply(fileArr, files);
            const newUploadImages = fileArr.map((file) => ({
                raw: file,
                url: createObjectURL(file),
            }));
            setUploadImages([...uploadImages, ...newUploadImages]);
        }
    }

    function doUploadImage(e: any) {
        const uploadPromises: Promise<storage.UploadTaskSnapshot>[] = [];
        uploadImages.forEach((image, index) => {
            uploadPromises.push(
                imageStorage.putFile(images.length + 1 + index, image.raw, IMAGES)
            );
        });
        Promise.all(uploadPromises)
            .then((x) => {
                console.log("uploaded");
                setUploadImages([]);
                loadImages();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function delScenarioItem(idx: number, data: any) {
        setScenario(scenario.filter((x, i) => i != idx));
    }

    const activeSymbol = (s) => {
        return s.isActive ? "○" : s.isDevActive ? "△" : "×";
    };
    const itemTitle = (s) => {
        return `「${s.title}」_${s.chapterIndex}_${s.chapterTitle}`;
    };

    const handleSetPageSize = (pageSize) => {
        setPageSize(pageSize)
        localStorage.setItem(pageSizeKey, pageSize);
    }

    return (
        <div className={"w-full"}>
            <div className={"flex flex-col gap-1"}>
                <div className={`bg-white p-2`}>
                    <ScenarioSelector
                        value={filename}
                        onChange={(e) => {
                            loadScenarioFile(e.target.value as any);
                            setLastScenario(e.target.value as any);
                        }}
                        newFileName={newFilename}
                        scenarioList={scenarioList}
                        callbackfn={(s: any) => {
                            return (
                                <MenuItem key={s.filename} value={s.filename}>
                                    {activeSymbol(s)} {itemTitle(s)}
                                </MenuItem>
                            );
                        }}
                    />
                    <LanguageSelector
                        value={lang}
                        onChange={(e) => setLang(e.target.value as any)}
                    />
                    <AutoSaveCheckbox
                        onChange={(e) => {
                            const f = e.target.checked;
                            autoSaveFlag = f;
                            setAutoSaveMessage(f ? "自動保存動作中" : "自動保存停止中");
                        }}
                        autoSaveMessage={autoSaveMessage}
                    />
                  <DeployButton
                      onClick={() => saveScript(false)}
                      filename={filename}
                      newFilename={newFilename}
                      deployMessage={deployMessage}
                  />
                </div>
                <MetadataView
                    filename={filename}
                    newFilename={newFilename}
                    value={cover}
                    onChange={(e) => setCover(e.target.value)}
                    value1={title}
                    onChange1={(e) => setTitle(e.target.value)}
                    value2={explain}
                    onChange2={(e) => setExplain(e.target.value)}
                    checked={isActive}
                    onChange3={(e) => setIsActive(e.target.checked)}
                    checked1={isDevActive}
                    onChange4={(e) => setIsDevActive(e.target.checked)}
                    checked2={isTool}
                    onChange5={(e) => setIsTool(e.target.checked)}
                    checked3={isOnlyImage}
                    onChange6={(e) => setIsOnlyImage(e.target.checked)}
                    value3={chapterIndex}
                    onChange7={(e) => setChapterIndex(parseInt(e.target.value))}
                    value4={chapterTitle}
                    onChange8={(e) => setChapterTitle(e.target.value)}
                    value5={chapterSubTitle}
                    onChange9={(e) => setChapterSubTitle(e.target.value)}
                    value6={chapterLink}
                    onChange10={(e) => setChapterLink(e.target.value)}
                    value7={image}
                    onChange11={setImage}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MentaroidView
                            usedMentoroid={usedMentoroid}
                            onChange={(e) =>
                                setUsedMentoroid({
                                    ena: e.target.checked,
                                    rabic: usedMentoroid.rabic,
                                })
                            }
                            onChange1={(e) =>
                                setUsedMentoroid({
                                    ena: usedMentoroid.ena,
                                    rabic: e.target.checked,
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DataStructureView/>
                    </Grid>
                </Grid>
            </div>
            <Grid container spacing={3} className={`!mt-3`}>
                <Grid item xs={6}>
                    <ScenarioContentBuilder
                        lang={lang}
                        langData={langData}
                        scenario={scenario}
                        enecolorGroupData={enecolorGroupData}
                        onDeleteItem={(idx: number, data: any) =>
                            delScenarioItem(idx, data)
                        }
                        updateScenario={(s: any) => setScenario(s)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <div className={`bg-white`}>
                        <BuilderSelector value={tabIndex} onChange={changeTabIndex}/>
                        <div className={`flex justify-center`}>
                            {tabIndex === 0 ? (
                                <TextBuilderList
                                    onClick={() => loadLangDB()}
                                    field={lang}
                                    data={langData}
                                    onClick1={(event, rowData) => {
                                        addText(rowData);
                                    }}
                                    pageSize={pageSize}
                                    handleSetPageSize={handleSetPageSize}
                                />
                            ) : tabIndex === 1 ? (
                                <div>
                                    <ImageBuilderUploader
                                        onChange={changeUploadImage}
                                        uploadImages={uploadImages}
                                        isInputExist={false}
                                        onClick={doUploadImage}
                                    />
                                    <ImageBuilderImageList
                                        images={images}
                                        element={(image: any, idx: number) => {
                                            return (
                                                <ImageBuilderImageItem
                                                    key={image.url}
                                                    image={image}
                                                    onClick={(e) => addImage(image.name, image.url)}
                                                    onShowModal={() => {
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            ) : tabIndex === 2 ? (
                                <InputBuilder
                                    onClick={(e) => addInput()}
                                    onClick1={(e) => addInputSlider()}
                                    onClick2={(e) => addChoice()}
                                />
                            ) : tabIndex === 3 ? (
                                <ControlBuilder
                                    onClick={(e) => addIf()}
                                    onClick1={(e) => addFor()}
                                    onClick2={(e) => addDelay()}
                                />
                            ) : tabIndex === 4 ? (
                                <MotionBuilder onClick={(e) => addMotion()}/>
                            ) : tabIndex === 5 ? (
                                <SlideBuilder onClick={(e) => addSlide()}/>
                            ) : null}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
