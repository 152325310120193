import {Checkbox, FormControlLabel} from '@material-ui/core';
import React from 'react';

export default function CheckboxControl({
    label,
    checked,
    onChange,
    ...checkboxProps
}) {
    return (
        <FormControlLabel
            key={label}
            control={
                <Checkbox
                    checked={checked}
                    onChange={onChange}
                    {...checkboxProps}
                />
            }
            label={label}
        />
    );
}
