import axios from "axios";
import {Button, MenuItem, Select, TextField} from "@material-ui/core";
import React, {memo, useEffect} from "react";
import {SlideItem} from "./Items";
import {firebaseFunc} from "../../utils/functions";

export function _SlideComponent(props: any) {

    const [selItem, setSelItem] = React.useState<any>(props.item);
    const [selectedPageData, setSelectedPageData] = React.useState<any>(null);
    const [pageThumbnail, setPageThumbnail] = React.useState(null);

    // const context = props.context as ScenarioContext
    // const store = props.store
    const item = props.item as SlideItem

    const loadSlide = async (item: SlideItem) => {
        item.error_message = "loading..."
        props.onUpdate(item);
        try {
            const slide = await axios.get(firebaseFunc(`load_slides?pid=${item.target_id}`));
            // console.log(slide.data)
            // console.log(item)
            if (!slide || !slide.data) {
                item.error_message = "読み込みエラー(プレゼンテーションID, 「togo-a2894@appspot.gserviceaccount.com」と共有しているかを確認してください)"
                props.onUpdate(item)
            } else {
                item.json = slide.data
                item.error_message = "ok"
                item.recent_object_id = selectedPageData?.objectId
                props.onUpdate(item)
                // setSelItem(item)
            }
            // console.log(item)
            setSelItem(item)
        } catch (e) {
            console.log(e)
            item.error_message = "読み込みエラー(プレゼンテーションID, 「togo-a2894@appspot.gserviceaccount.com」と共有しているかを確認してください)"
            props.onUpdate(item)
        }
    }
    useEffect(() => {
        const slides = selItem?.json?.slides ?? selItem?.slides
        if (!slides)
            return;
        setSelectedPageData(slides[item.page_no] ?? slides[0])
    }, [item.page_no, selItem])

    useEffect(() => {
        if (!selItem?.json?.presentationId)
            return;
        // console.log({selectedPageData})
        fetchPageThumbnail(selItem.json.presentationId, selectedPageData)
            .then(data => {
                setPageThumbnail(data?.pageThumbnail?.contentUrl)
            })
    }, [selectedPageData])

    useEffect(() => {
        const _item = {...item}
        _item.recent_object_id = selectedPageData?.objectId;
        props.onUpdate(_item)
    }, [selectedPageData])

    // @ts-ignore
    return (
        <div>
            <TextField
                size="small"
                id="outlined-name"
                label="プレゼンテーションID"
                value={item.target_id}
                onChange={(e) => {
                    item.target_id = e.target.value as string
                    props.onUpdate(item);
                }}
                style={{width: "100%"}}
                multiline={false}
                margin="none"
                variant="outlined"
            />

            <div className={'flex flex-row justify-between m-1'}>
                <div id={'slide-info'}>
                    <div>
                        <Button size="small"
                                variant="contained"
                                disabled={item.target_id == ""}
                                onClick={(e) => {
                                    loadSlide(item)
                                }}
                        >読み込み</Button>
                    </div>
                    {
                        item.error_message &&
                        <div>
                            <span style={{color: "red", fontSize: "small"}}>{item.error_message}</span>
                        </div>
                    }
                    {item.json ? <div>[{item.json.title}]</div> : null}
                    <div>
                        ページ：
                        <Select
                            value={item.page_no}
                            onChange={(e) => {
                                item.page_no = parseInt(e.target.value as string)
                                props.onUpdate(item)
                                setSelItem(item)
                                setSelectedPageData(item.json.slides[item.page_no])
                            }}
                        >
                            {
                                (item.json && item.json.slides) ?
                                    item.json.slides.map((s, ui) => {
                                        return <MenuItem key={ui} value={ui}>{ui + 1}</MenuItem>
                                    })
                                    : null
                            }
                        </Select>
                    </div>
                </div>
                <div id={'slide-preview'} className={'w-1/2'}>
                    <SlidePageThumb pageThumbnail={pageThumbnail ?? ''}/>
                </div>
            </div>
        </div>
    )
}

const SlidePageThumb: React.FC<{ pageThumbnail: string }> = memo(function _T({pageThumbnail}: { pageThumbnail: string }) {
    if (!pageThumbnail)
        return null;
    return <img src={pageThumbnail} style={{width: "100%"}} alt={"thumb"}/>
})

const fetchPageThumbnail = async (presentationId: string, page: any) => {
    if (!page?.objectId)
        return;
    //curl --location --request GET 'https://asia-northeast1-togo-a2894.cloudfunctions.net/load_slide_with_page_thumbnail?pageObjectId=g19560415fdb_0_0&pid=1IfdqVZ7PYnCg4Hl-O7S7CrvfU43TNgtdNSLjyccFLJQ&thumbOnly=yes&thumbSize=m'
    const url = firebaseFunc(`load_slide_with_page_thumbnail`)
    const res = await axios.get(url, {
        params: {
            pid: presentationId,
            pageObjectId: page.objectId,
            thumbOnly: 'yes',
            thumbSize: 'm'
        }
    })
    return res.data
}
