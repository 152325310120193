import {ItemComponent} from "./ItemComponent";
import React from "react";
import {ImageItem} from "./Items";

export class ImageComponent extends ItemComponent {
    constructor(props) {
        super(props)
    }

    render() {
        const item = this.props.item as ImageItem
        return <div>
            {
                item.url == "" ?
                    <div key={1} style={{width: "100px", height: "100px"}}>画像なし</div> :
                    <img key={2} src={item.url} width="100px"/>
            }
        </div>
    }
}
