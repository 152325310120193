import React, {useEffect, useState} from "react";
import {DataStorage, DataUnit} from "../../model/DataStructure";
import {definitionStore} from "../../firebase/store";
import {Button, TextField} from "@material-ui/core";
import {NormalButton} from "../../component/NormalButton";
import {DeleteButton} from "../../component/DeleteButton";
import {AddButton} from "../../component/AddButton";
import {DraggableListItem} from "./DraggableListItem";
import DeleteModal from "../../component/DeleteModal";
import {useModal} from "../../hooks/useModal";
import {DeleteIcon} from "../../component/DeleteIcon";

type Props = {
    store: any,
}

export function Page({store}: Props) {
    const [deployMessage, setDeployMessage] = useState<string>('');
    const [sending, setSending] = useState<boolean>(false);
    const [reloadMessage, setReloadMessage] = useState<string>("読み込み");
    let storages = store.get("storages")
    const {isDeleteModal, toggleDeleteModal} = useModal();
    const [delStorageId, setDelStorageId] = useState<any>(null);
    const [delUnitId, setDelUnitId] = useState<any>(null);


    useEffect(() => {
        loadScript()
    }, [])

    function delData(si: number, di: number, dt: any) {
        let s = store.get("storages")
        s[si].data = s[si].data.filter((x, i) => i != di)
        store.set("storages")(s)
    }

    function addData(idx: number, d: DataUnit) {
        let s = store.get("storages")
        s[idx].data.push(d)
        store.set("storages")(s)
    }

    function updateStorage(idx: number, s: DataStorage) {
        let st = store.get("storages")
        st[idx] = s;
        store.set("storages")(st)
    }

    function addStorage(s: DataStorage) {
        let st = store.get("storages")
        st.push(s)
        store.set("storages")(st)
    }

    function saveScript() {
        let obj = store.get("storages")

        let metadata = {
            customMetadata: {}
        }
        setDeployMessage('保存中');
        setSending(true)
        definitionStore.save(JSON.stringify(obj), metadata).then(() => {
            setDeployMessage('完了');
            setSending(false)
        }).catch(err => {
            setDeployMessage('エラー');
            setSending(false)
            console.log(err)
        }).finally(() => {
            setDeployMessage('');
            setSending(false)
        })
    }

    function loadScript() {
        setReloadMessage('読み込み中...')
        definitionStore.load().then(x => {
            store.set("storages")(x.data)
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setReloadMessage('読み込み...')
        })
    }

    function onDeleteStorage(idx: number) {
        let st = store.get("storages")
        st = st.filter((s, si) => si != idx)
        store.set("storages")(st)
    }

    function handleDeleteStorage(si: number) {
        setDelStorageId(si);
        toggleDeleteModal(true)
    }

    function handleDeleteUnit(si: number, di: number, dt: any) {
        setDelStorageId(si)
        setDelUnitId(di)
        toggleDeleteModal(true)
    }

    function handleYes() {
        if (delUnitId && delStorageId)
            delData(delStorageId, delUnitId, null)
        else
            onDeleteStorage(delStorageId)
        setDelUnitId(null)
        setDelStorageId(null)
        toggleDeleteModal(false);
    }

    return <div className={'w-full'}>
        {/*<MyComponent/>*/}
        <div className={'flex flex-col flex-wrap gap-3 w-full'}>
            <div className={'flex flex-wrap m-2 justify-between'}>
                <div className={'flex gap-2'}>
                    <Button
                            className={`!bg-light-blue-2`}
                            variant="contained"
                            onClick={(e) => loadScript()}
                    >{reloadMessage}</Button>
                    <NormalButton
                        onClick={(e) => addStorage(new DataStorage())}
                        text={'+'}
                        className={'w-12'}
                    />
                </div>
                <div>
                    <NormalButton
                            icon={'images/icons/archive.svg'}
                        onClick={(e) => saveScript()}
                        text={deployMessage ? '保存中...' : '保存'}
                        className={'bg-green-800 w-20'}
                    />
                </div>

            </div>
            <div className={'flex gap-3'}>
                {
                    storages.map((storage, si) => {
                        return (
                            <>
                                <div className={'flex flex-col'}
                                     key={storage.id}>
                                    <div className={'bg-white flex'}>
                                        <div className={'flex-1'}>
                                            <TextField
                                                id="outlined-name"
                                                // label="ストレージ名"
                                                value={storage.name}
                                                InputProps={{
                                                    style: {
                                                        color: "black",
                                                        fontWeight: "bold",
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    storage.name = e.target.value as string
                                                    updateStorage(si, storage)
                                                }}
                                                margin="none"
                                                variant="outlined"
                                                size="small"
                                            />
                                        </div>
                                        {/*delete icon*/}
                                        <div className={`my-auto mr-1`}>
                                            <DeleteIcon
                                                icon={'images/icons/trash.svg'}
                                                onClick={(e) => handleDeleteStorage(si)}
                                            />
                                        </div>
                                    </div>
                                    <DraggableListItem
                                        storage={storage}
                                        onDeleteItem={(idx: number, dt: any) => handleDeleteUnit(si, idx, dt)}
                                        updateStorage={(s: any) => updateStorage(si, s)}/>
                                    <AddButton
                                        onClick={(e) => addData(si, new DataUnit())}
                                    />
                                </div>

                            </>
                        )
                    })
                }
            </div>
        </div>
        <DeleteModal
            title={`削除しますか？`}
            show={isDeleteModal}
            setShow={toggleDeleteModal}
            onYes={handleYes}
            onNo={() => {
                toggleDeleteModal(false);
            }}
        ></DeleteModal>
    </div>
}
