import React from "react";

export class DeleteIcon extends React.Component<{ icon?: any, onClick: any, className?: string }> {
    render() {
        let {icon, onClick, className} = this.props;
        return (
            <img
                src={icon} alt=""
                className={`w-6 h-6 cursor-pointer border-none hover:scale-125 ${className ?? ''}`}
                onClick={onClick}
            />
        )
    }
}
