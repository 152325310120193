import {
    Button,
    FormControl,
    Grid,
    InputBase,
    makeStyles,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField, withStyles
} from "@material-ui/core";
import React, {useRef, useState} from "react";
import {StorageSelectComponent} from "./components";
import {autoSaveFlag} from "./ScenarioPage";
import CheckboxControl from "../../component/CheckboxControl";
import ScenarioDelete from "./ScenarioDelete";

export const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
        minWidth: "120px"
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

export function AutoSaveCheckbox(props: { onChange: (e) => void, autoSaveMessage: string }) {
    return <div>
        <CheckboxControl
            id="is_active"
            checked={autoSaveFlag}
            onChange={props.onChange}
            label={'自動保存'}
        />
        <div className={"m-1 text-sm"}>{props.autoSaveMessage}</div>
    </div>;
}

export function ScenarioSelector(props: { value: string, onChange: (e) => void, newFileName: string, scenarioList: any[], callbackfn: (s: any) => JSX.Element }) {
    return (
        <div className={'flex flex-row gap-3'}>
            <div>
                <div>シナリオ選択</div>
                <div className={`flex`}>
                    <Select
                        value={props.value}
                        onChange={props.onChange}
                        input={<BootstrapInput/>}
                        className={'mr-2'}
                    >
                        <MenuItem value={props.newFileName}>新規で作る</MenuItem>
                        {
                            props.scenarioList.map(props.callbackfn)
                        }
                    </Select>
                    <ScenarioDelete/>
                </div>
            </div>
        </div>
    )
}

export function LanguageSelector(props: { value: string, onChange: (e) => void }) {
    return <Grid item xs={1} md={3}>
        <div>表示言語：</div>
        <Select
            value={props.value}
            onChange={props.onChange}
            input={<BootstrapInput/>}
            className={'mr-2'}
        >
            <MenuItem value={"japanese"}>日本語</MenuItem>
            <MenuItem value={"english"}>English</MenuItem>
        </Select>
    </Grid>;
}

export function DeployButton(props: { onClick: () => void, filename: string, newFilename: string, deployMessage: string }) {
    return <Grid item xs={1} md={3}>
        <Button color="primary" variant="contained" onClick={props.onClick}>
            デプロイ（{props.filename == props.newFilename ? "作成" : "更新"}）
        </Button>
        <div className={"m-2 text-sm"}>{props.deployMessage}</div>
    </Grid>;
}

export function MetadataView(props: { filename: string, newFilename: string, value: string, onChange: (e) => void, value1: string, onChange1: (e) => void, value2: string, onChange2: (e) => void, checked: boolean, onChange3: (e) => void, checked1: boolean, onChange4: (e) => void, checked2: boolean, onChange5: (e) => void, checked3: boolean, onChange6: (e) => void, value3: number, onChange7: (e) => void, value4: string, onChange8: (e) => void, value5: string, onChange9: (e) => void, value6: any, onChange10: (e) => void, value7: any, onChange11: (e) => void }) {
    const [tabValue, setTabValue] = useState(0);
    const inputThumbRef = useRef<any>(null)
    return <Grid item xs={12} md={12}>
        <div className={`my-2`}>
            <div className={`p-2 bg-white`}>
                <div className={`p-2 border-solid font-bold border-0 border-b border-gray-200`}>
                    メタデータ
                </div>
                <Grid className={`!my-2`} container spacing={3}>
                    <Grid item xs={12} md={5}>
                        <div>
                            ファイルID: {props.filename} {props.filename == props.newFilename ?
                            <span style={{color: "red", fontWeight: "bold"}}>[新規]</span> : ""}
                        </div>
                        <TextField
                            value={props.value}
                            onChange={props.onChange}
                            margin="none"
                            variant="outlined"
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <div>タイトル</div>
                        <TextField
                            value={props.value1}
                            onChange={props.onChange1}
                            margin="none"
                            variant="outlined"
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <div>全体説明文(最初のチャプターのみ入力)</div>
                        <TextField
                            id="explain"
                            value={props.value2}
                            onChange={props.onChange2}
                            variant="outlined"
                            fullWidth
                            size="small"
                        />
                    </Grid>
                </Grid>
                <CheckboxControl
                    id="is_active"
                    checked={props.checked}
                    onChange={props.onChange3}
                    label="表示する"
                />
                <CheckboxControl
                    id="is_dev_active"
                    checked={props.checked1}
                    onChange={props.onChange4}
                    label="開発用に表示する"
                />
                <CheckboxControl
                    id="is_tool"
                    checked={props.checked2}
                    onChange={props.onChange5}
                    label="ツール"
                />
                <CheckboxControl
                    id="is_only_image"
                    checked={props.checked3}
                    onChange={props.onChange6}
                    label="表紙は画像のみ（この場合、5:7(2048px:1463px)の画像にしてください）"
                />
            </div>

            {/*<Checkbox*/}
            {/*    id="is_active"*/}
            {/*    checked={props.checked}*/}
            {/*    onChange={props.onChange3}*/}
            {/*/>表示する*/}
            {/*<br/>*/}
            {/*<Checkbox*/}
            {/*    id="is_dev_active"*/}
            {/*    checked={props.checked1}*/}
            {/*    onChange={props.onChange4}*/}
            {/*/>開発用に表示する*/}
            {/*<br/>*/}
            {/*<Checkbox*/}
            {/*    id="is_tool"*/}
            {/*    checked={props.checked2}*/}
            {/*    onChange={props.onChange5}*/}
            {/*/>ツール*/}
            {/*<br/>*/}
            {/*<Checkbox*/}
            {/*    id="is_only_image"*/}
            {/*    checked={props.checked3}*/}
            {/*    onChange={props.onChange6}*/}
            {/*/>表紙は画像のみ（この場合、5:7(2048px:1463px)の画像にしてください）*/}

            <div className={`bg-white p-2 mt-3`}>
                <Tabs className={`my-3`} value={tabValue} onChange={(event: any, newValue: any) => {
                    setTabValue(newValue);
                }}>
                    <Tab label="Add chapter details"/>
                    <Tab label="Add chapter link"/>
                </Tabs>
                {tabValue === 0 &&
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <div>チャプター番号</div>
                            <TextField
                                id="chapter_idx"
                                value={props.value3}
                                onChange={props.onChange7}
                                margin="none"
                                variant="outlined"
                                size="small"
                                fullWidth
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div>チャプタータイトル</div>
                            <TextField
                                id="chapter_title"
                                value={props.value4}
                                onChange={props.onChange8}
                                margin="none"
                                variant="outlined"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div>チャプターサブタイトル</div>
                            <TextField
                                id="chapter_sub_title"
                                value={props.value5}
                                onChange={props.onChange9}
                                margin="none"
                                variant="outlined"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                    </Grid>
                }
                {tabValue === 1 &&
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <div>チャプター番号</div>
                            <TextField
                                id="chapter_idx"
                                label=""
                                value={props.value3}
                                onChange={props.onChange7}
                                margin="none"
                                variant="outlined"
                                size="small"
                                fullWidth
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div>Chapter link</div>
                            <TextField
                                id="chapter_link"
                                value={props.value6}
                                onChange={props.onChange10}
                                margin="none"
                                variant="outlined"
                                size="small"
                                type={`text`}
                                fullWidth
                                placeholder={`https://www.youtube.com`}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} className={`flex items-center`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="red"
                                 className="bi bi-youtube" viewBox="0 0 16 16">
                                <path
                                    d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
                            </svg>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <input
                                name={"thumbnail"}
                                type='file'
                                ref={inputThumbRef}
                                onChange={(e) => {
                                    if (e.target.files && e.target.files[0]) {
                                        const fileImage = e.target.files[0];
                                        const previewImageUrl = URL.createObjectURL(fileImage);
                                        props.onChange11({file: fileImage, url: previewImageUrl});
                                    }
                                }}
                                className={`hidden`}
                                accept=".jpg,.jpeg,.png"
                            />
                            <div>
                                <div className={`text-gray-500`}>Thumbnail:</div>
                                <div
                                    className={`w-20 h-20 flex flex-col justify-center items-center relative border-dashed border-2 border-light-gray cursor-pointer`}
                                    onClick={() => inputThumbRef.current.click()}
                                >
                                    {props.value7?.url && props.value7?.url !== "" ?
                                        <img src={props.value7.url}
                                             className={'w-full h-full object-contain cursor-pointer'}
                                             alt="chapter_thumb"/> :
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26"
                                             height="26" fill="currentColor"
                                             className={"bi bi-upload select-none outline-none"} viewBox="0 0 16 16">
                                            <path
                                                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                            <path
                                                d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
                                        </svg>}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                }
            </div>
        </div>
    </Grid>;
}

export function
MentaroidView(props: { usedMentoroid: { ena: boolean; rabic: boolean }, onChange: (e) => void, onChange1: (e) => void }) {
    return <Grid item xs={12} md={12}>
        <div className={`p-2 bg-white`}>
            <div className={`p-2 border-solid font-bold border-0 border-b border-gray-200`}>
                メンタロイド
            </div>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    {/*<FormControlLabel*/}
                    {/*    key={'エナ'}*/}
                    {/*    control={*/}
                    {/*        <Checkbox*/}
                    {/*            id="is_ena_active"*/}
                    {/*            checked={props.usedMentoroid.ena}*/}
                    {/*            onChange={props.onChange}*/}
                    {/*        />*/}
                    {/*    }*/}
                    {/*    label="エナ"*/}
                    {/*/>*/}

                    <CheckboxControl
                        id="is_ena_active"
                        checked={props.usedMentoroid.ena}
                        onChange={props.onChange}
                        label="エナ"
                    />
                    <CheckboxControl
                        id="is_rabic_active"
                        checked={props.usedMentoroid.rabic}
                        onChange={props.onChange1}
                        label="ラビック"
                    />
                </Grid>
            </Grid>
        </div>
    </Grid>;
}

export function DataStructureView() {
    return <Grid item xs={12} md={12}>
        <div className={`p-2 bg-white`}>
            <div className={`p-2 border-solid font-bold border-0 border-b border-gray-200`}>
                参照データ構造
            </div>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <StorageSelectComponent/>
                </Grid>
            </Grid>
        </div>
    </Grid>;
}
