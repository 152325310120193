import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'
import TopPage from './Pages/TopPage'
import Store from './Store/CommonStore'
import {Toaster} from "react-hot-toast";

const App = () => (
    <Store.Container>
        <BrowserRouter>
            <Route path='/' component={TopPage}/>
        </BrowserRouter>
        <Toaster/>
    </Store.Container>
)

export default App;
