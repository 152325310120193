import React from "react";
import {Checkbox, FormControlLabel} from "@material-ui/core";

export class _StorageSelectComponent extends React.Component<any, {}> {
    render() {
        const store = this.props.store;
        const storage = store.get("storages")
        const checkedStorage = store.get("checkedStorage")
        return <div>
            {
                storage.map((storage, si) => {
                    return <FormControlLabel
                        key={si}
                        control={
                            <Checkbox
                                checked={storage.name in checkedStorage}
                                onChange={(e) => {
                                    const cs = checkedStorage
                                    if (storage.name in cs) {
                                        delete cs[storage.name]
                                    } else {
                                        cs[storage.name] = true;
                                    }
                                    store.set("checkedStorage")(cs)
                                }}
                            />
                        }
                        label={storage.name}
                    />
                })}
        </div>
    }
}
