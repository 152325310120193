import {ItemComponent} from "./ItemComponent";
import {Checkbox, FormControl, MenuItem, Select, TextField} from "@material-ui/core";
import React from "react";
import {GenUnits} from "./ScenarioPage";
import {InputItem} from "./Items";

export class _InputComponent extends ItemComponent {
    constructor(props) {
        super(props)
    }

    render() {
        const store = this.props.store
        const units = GenUnits(store)
        const item = this.props.item as InputItem

        return <div>
            <FormControl variant="outlined" margin='dense'>
                <Select
                    value={JSON.stringify({
                        targetId: item.target_id,
                        input_data: item.input_data
                    })}
                    onChange={(e) => {
                        const targetId = JSON.parse(e.target.value as string).targetId;
                        const input_data = JSON.parse(e.target.value as string).input_data;
                        item.target_id = targetId;
                        item.input_data = input_data;
                        this.props.onUpdate(item)
                    }}
                >
                    {
                        units.map((unit, ui) => {
                            return <MenuItem key={ui}
                                             value={JSON.stringify({
                                                     targetId: unit.unit.id,
                                                     input_data: `${unit.storage.name}:${unit.unit.name}`
                                                 }
                                             )}>{unit.storage.name}:{unit.unit.name}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
            <Checkbox
                id="is_active"
                checked={item.is_enecolor_bar === true}
                onChange={(e) => {
                    item.is_enecolor_bar = (e.target.checked === true)
                    this.props.onUpdate(item)
                }}
            />エネカラーバー
            {
                item.input_type == "number" ?
                    <div style={{margin: "10px"}}>
                        <TextField
                            style={{width: "100px"}}
                            label="最小値"
                            value={item.minValue}
                            onChange={(e) => {
                                let s = e.target.value
                                item.minValue = parseFloat(s);
                                this.props.onUpdate(item)
                            }}
                            type="number"
                            margin="none"
                            variant="outlined"
                            size="small"
                        />
                        〜
                        <TextField
                            style={{width: "100px"}}
                            label="最大値"
                            value={item.maxValue}
                            onChange={(e) => {
                                let s = e.target.value
                                item.maxValue = parseFloat(s);
                                this.props.onUpdate(item)
                            }}
                            margin="none"
                            variant="outlined"
                            size="small"
                        />
                    </div>
                    : null
            }
        </div>
    }
}
