import {firestore} from "firebase";
import {db} from "./app";

export const bannersRef = db.collection("banners");
export const popupsRef = db.collection("popups");

class FireStore {
    async putFile(
        fileName: any,
        url: string,
        pageUrl: string,
        ref: firestore.CollectionReference<firestore.DocumentData>
    ) {
        ref
            .doc()
            .set({name: fileName, url, pageUrl})
            .catch((err) => console.error(err));
    }

    async delFile(
        fileId: any,
        ref: firestore.CollectionReference<firestore.DocumentData>
    ) {
        ref
            .doc(fileId)
            .delete()
            .catch((err) => console.error(err));
    }

    async updateFile(
        fileId: any,
        ref: firestore.CollectionReference<firestore.DocumentData>,
        pageUrl: string | "",
        isShow?: Boolean
    ) {
        ref
            .doc(fileId)
            .update({pageUrl, isShow})
            .catch((err) => console.error(err));
    }

    async updateIsShowFile(
        fileId: string,
        ref: firestore.CollectionReference<firestore.DocumentData>,
        isShow?: Boolean
    ) {
        ref
            .doc(fileId)
            .update({isShow: !isShow})
            .catch((err) => console.error(err));
    }
}

export const imgFireStore = new FireStore();
