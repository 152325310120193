import React from "react";

export class NormalButton extends React.Component<{
    text: any;
    onClick: any;
    className?: string;
    isUploading?: boolean;
    icon?: any;
}> {
    render() {
        let {text, onClick, className, isUploading, icon} = this.props;
        return (
            <button
                className={`!bg-dark-blue text-white cursor-pointer hover:bg-black  text-white rounded border-none p-2 ${
                    className ?? ""
                }`}
                onClick={onClick}
            >{icon && <img className={`w-5 h-5 mr-1 -mt-1 align-middle`} src={icon} alt=""/>}
                {isUploading ? "読み込み中..." : text}
            </button>
        );
    }
}
