import {lastScenarioAtom, lastScenarioDataAtom} from '../../atoms/lastScenario';
import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import React, {useMemo} from 'react';
import DeleteModal from '../../component/DeleteModal';
import {scenarioListRef} from '../../firebase/store';
import toast from 'react-hot-toast';
import {
    firstScenarioFileNameAtom,
    scenarioListAtom,
} from '../../atoms/scenarioList.atom';

export default function ScenarioDelete() {

    const beDeletedScenarioData = useAtomValue(lastScenarioDataAtom);
    const setLastScenario = useSetAtom(lastScenarioAtom);
    const firstScenarioFileName = useAtomValue(firstScenarioFileNameAtom);
    const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
    const [scenarioList, setScenarioList] = useAtom(scenarioListAtom);

    const onDelete = () => {
        // console.log(scenarioListRef.child(beDeletedScenarioData.filename).fullPath);
        scenarioListRef.child(beDeletedScenarioData.filename).delete().then(
            () => {
                toast.success(`${delTitle}削除しました`);
                setScenarioList(scenarioList?.filter(
                    (sc) => sc.filename !== beDeletedScenarioData.filename),
                );
            },
        ).catch(e => {
            alert(e.message);
        });
    };

    // useEffect(() => {
    //     console.log({firstScenarioFileName});
    //
    //     if (!scenarioList?.length || !beDeletedScenarioData)
    //         return;
    //     if (!includes(
    //         map(scenarioList, s => s?.filename),
    //         beDeletedScenarioData.filename)
    //     ) {
    //         console.log('set');
    //         setLastScenario(firstScenarioFileName);
    //     }
    // }, [beDeletedScenarioData, firstScenarioFileName, scenarioList]);

    const delTitle = useMemo(() => {
        if (!beDeletedScenarioData)
            return;
        return `\`${beDeletedScenarioData.title}\` (${beDeletedScenarioData.filename})`;
    }, [beDeletedScenarioData]);

    return (
        <div>
            <img
                src={`images/icons/iconDelete.svg`} alt=""
                className={`w-10 h-10 cursor-pointer mt-1`}
                onClick={() => setShowDeleteConfirm(true)}
            />
            {
                beDeletedScenarioData && <DeleteModal
                    title={delTitle + `を削除しますか？`}
                    show={showDeleteConfirm}
                    setShow={setShowDeleteConfirm}
                    // scenario={beDeletedScenarioData}
                    onYes={() => {
                        console.log('delete');
                        console.log(beDeletedScenarioData);
                        setShowDeleteConfirm(false);
                        onDelete();
                    }}
                    onNo={() => {
                        console.log('cancel');
                        setShowDeleteConfirm(false);
                    }}
                />
            }
        </div>
    );
}
