import React, {RefObject, useRef, useState} from "react";
import {AppBar, Tab, Tabs, TextField} from "@material-ui/core";
import MaterialTable from "material-table";
import {LangDBData} from "./ScenarioPage";
import {AddButton} from "../../component/AddButton";
import {NormalButton} from "../../component/NormalButton";
import {EditButton} from "../../component/EditButton";
import ShowButton from "../../component/ShowButton";
import {DeleteIcon} from "../../component/DeleteIcon";

export function TextBuilderList(props: {
    onClick: () => void;
    field: string;
    data: LangDBData[];
    onClick1: (event, rowData) => void;
    pageSize: number,
    handleSetPageSize: (arg: number) => void
}) {
    return (
        <div>
            <NormalButton
                text={"再読み込み"}
                onClick={props.onClick}
                className={"m-2"}
            />
            <MaterialTable
                style={{boxShadow: "none"}}
                key={props.pageSize}
                columns={[
                    {title: "カテゴリ", field: "category"},
                    {title: "メッセージ", field: props.field},
                ]}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to}件を表示（全{count}件）'
                    },
                    toolbar: {

                        nRowsSelected: '{0} row(s) selected'
                    },
                    header: {
                        actions: 'アクション'
                    },
                    body: {
                        emptyDataSourceMessage: 'No records to display',
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}
                onChangeRowsPerPage={props.handleSetPageSize}
                data={props.data}
                title="言語DB"
                options={{
                    pageSize: props.pageSize,
                    pageSizeOptions: [5, 10, 20, 30],
                    toolbar: true,
                    paging: true,
                    actionsColumnIndex: -1
                }}
                actions={[
                    {
                        icon: "add",
                        tooltip: "シナリオに追加",
                        onClick: props.onClick1,
                    },
                ]}
            />
        </div>
    );
}

export function ImageBuilderUploader(props: {
    onChange: (e: any) => void;
    uploadImages: any;
    isUploading?: boolean;
    isInputExist: boolean;
    onClick: (e: any, ref?: RefObject<HTMLInputElement | null>) => void;
}) {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const textInputRef = useRef<HTMLInputElement | null>(null);
    return (
        <div>
            <input
                type="file"
                accept="image/png"
                onChange={props.onChange}
                multiple
                ref={inputRef}
            />
            <div className={`flex gap-1 items-center`}>
                {props.uploadImages.map((image) => (
                    <div className={`flex gap-1 flex-col justify-center`}>
                        <img key={image.url} src={image.url} alt="banner" width="100px"/>
                        {props.isInputExist && (
                            <TextField
                                label="リンク"
                                size="small"
                                margin="none"
                                variant="outlined"
                                inputRef={textInputRef}
                                placeholder="https://www.example.com"
                            />
                        )}
                    </div>
                ))}
            </div>
            <NormalButton
                isUploading={props.isUploading || false}
                onClick={async (e) => {
                    await props.onClick(e, textInputRef);
                    if (inputRef.current !== null) {
                        inputRef.current.value = "";
                    }
                }}
                text={"アップロード"}
            />
        </div>
    );
}

export function ImageBuilderImageList(props: {
    images: any;
    element: (image: any, idx: number) => JSX.Element;
}) {
    return (
        <div className={"grid place-items-center grid-cols-3 gap-1"}>
            {props.images.map(props.element)}
        </div>
    );
}

export function ImageBuilderImageItem(props: {
    image: any;
    onClick: (e, ref: RefObject<HTMLInputElement | null>) => void;
    onApply?: (e, ref: RefObject<HTMLInputElement | null>) => void;
    onShowModal?: (e) => void;
    onShowClick?: (e) => void;
    isBannerPopup?: boolean;
}) {
    const textInputRef = useRef<HTMLInputElement | null>(null);
    const [isInput, setIsInput] = useState(false);
    return (
        <div className={"flex flex-col items-center justify-end"}>
            {props.image.url == "" ? (
                <div
                    style={{
                        width: "100px",
                        aspectRatio: "1/1",
                    }}
                >
                    画像なし
                </div>
            ) : (
                <a href={props.image.pageUrl} target="_blank">
                    <img
                        src={props.image.url}
                        width="100px"
                        style={{
                            margin: "5px",
                            border: "solid 1px",
                        }}
                        className={`${(!props.image.isShow && props.isBannerPopup) ? `opacity-20` : `opacity-100`} hover:opacity-100`}
                        alt={props.image.name}
                    />
                </a>
            )}

            <TextField
                label="ページ"
                size="small"
                margin="none"
                variant="outlined"
                inputRef={textInputRef}
                placeholder="https://www.example.com"
                style={{display: isInput ? "block" : "none"}}
            />

            {/*<Button variant="contained"*/}
            {/*        onClick={props.onClick}*/}
            {/*>＋</Button>*/}

            <DeleteIcon
                icon={'images/icons/iconX.svg'}
                onClick={props.onShowModal}
            />
            {props.isBannerPopup ? (
                <>
                    <EditButton
                        text={!isInput && `編集`}
                        onClick={
                            isInput
                                ? (e) => {
                                    if (props.onApply) {
                                        props.onApply(e, textInputRef);
                                        setIsInput(!isInput);
                                    }
                                }
                                : (e) => {
                                    props.onClick(e, textInputRef);
                                    setIsInput(!isInput);
                                }
                        }
                        className={"w-1/2"}
                    />
                    <ShowButton onClick={props.onShowClick} className={"w-1/2"}
                                text={props.image.isShow ? "Hide" : "Show"}></ShowButton>
                </>
            ) : (
                <AddButton onClick={props.onClick} className={"w-1/2"}/>
            )}
        </div>
    );
}

export function InputBuilder(props: {
    onClick: (e) => void;
    onClick1: (e) => void;
    onClick2: (e) => void;
}) {
    return (
        <div>
            <div style={{margin: "10px"}}>
                <NormalButton
                    text={`文字入力を追加`}
                    className={`w-36`}
                    onClick={props.onClick}/>
            </div>
            <div style={{margin: "10px"}}>
                <NormalButton
                    text={`数値入力を追加`}
                    className={`w-36`}
                    onClick={props.onClick1}/>
            </div>
            <div style={{margin: "10px"}}>
                <NormalButton
                    text={`選択肢を追加`}
                    className={`w-36`}
                    onClick={props.onClick2}/>
            </div>
        </div>
    );
}

export function ControlBuilder(props: {
    onClick: (e) => void;
    onClick1: (e) => void;
    onClick2: (e) => void;
}) {
    return (
        <div>
            <div style={{margin: "10px"}}>
                <NormalButton
                    text={"条件分岐を追加"}
                    className={`w-36`}
                    onClick={props.onClick}>
                </NormalButton>
            </div>
            <div style={{margin: "10px"}}>
                <NormalButton
                    text={`繰り返しを追加`}
                    className={`w-36`}
                    onClick={props.onClick1}/>
            </div>
            <div style={{margin: "10px"}}>
                <NormalButton
                    text={`時間待ちを追加`}
                    className={`w-36`}
                    onClick={props.onClick2}/>
            </div>
        </div>
    );
}

export function MotionBuilder(props: { onClick: (e) => void }) {
    return (
        <div>
            <div style={{margin: "10px"}}>
                <NormalButton
                    text={'メンタロイド操作を追加'}
                    onClick={props.onClick}>
                </NormalButton>
            </div>
        </div>
    );
}

export function SlideBuilder(props: { onClick: (e) => void }) {
    return (
        <div>
            <div style={{margin: "10px"}}>
                <NormalButton
                    text={'スライド'}
                    onClick={props.onClick}/>
            </div>
            <div>
                ※スライドは[togo-a2894@appspot.gserviceaccount.com]と共有してください。
            </div>
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `operation-tab-${index}`,
        "aria-controls": `operation-tabpanel-${index}`,
    };
}

export function BuilderSelector(props: {
    value: number;
    onChange: (e: any, newValue: number) => void;
}) {
    return (
        <AppBar position="static"
                className={`!font-bold !text-black !bg-white !shadow-none border-solid border-0 border-b border-gray-200`}>
            <Tabs
                value={props.value}
                variant="scrollable"
                onChange={props.onChange}
                aria-label="operation-tab"
            >
                <Tab className={`!font-bold !text-black !bg-white p-0`} label="テキスト" {...a11yProps(0)} />
                <Tab className={`!font-bold !text-black !bg-white p-0`} label="画像" {...a11yProps(1)} />
                <Tab className={`!font-bold !text-black !bg-white p-0`} label="入力" {...a11yProps(2)} />
                <Tab className={`!font-bold !text-black !bg-white p-0`} label="制御" {...a11yProps(3)} />
                <Tab className={`!font-bold !text-black !bg-white p-0`} label="モーション" {...a11yProps(4)} />
                <Tab className={`!font-bold !text-black !bg-white p-0`} label="スライド" {...a11yProps(5)} />
            </Tabs>
        </AppBar>
    );
}

export function BuilderBannerPopupSelector(props: {
    value: number;
    onChange: (e: any, newValue: number) => void;
}) {
    return (
        <AppBar position="static"
                className={`!font-bold !text-black !bg-white !shadow-none border-solid border-0 border-b border-gray-200`}>
            <Tabs
                value={props.value}
                onChange={props.onChange}
                aria-label="operation-tab"
            >
                <Tab className={`!font-bold !text-black !bg-white p-0`} label="バナー" {...a11yProps(0)} />
                <Tab className={`!font-bold !text-black !bg-white p-0`} label="ポップアップ" {...a11yProps(1)} />
            </Tabs>
        </AppBar>
    );
}
