import {firebaseApp} from "./app";
import axios from "axios";

export const storage = firebaseApp.storage();
export const scenarioListRef = storage.ref(`scenarios`);

class ScenarioStore {
    async putFile(fileId: any, dataStr: string, metadata: any) {
        let storageRef = storage.ref();
        let ref = storageRef.child(`scenarios/${fileId}`);
        return ref.putString(dataStr).then(
            async (snapshot) => {
                await ref.updateMetadata(metadata);
                return snapshot;
            },
            (err) => {
                console.log(err);
            }
        );
    }

    async getFile(fileId: any) {
        let storageRef = storage.ref();
        let ref = storageRef.child(`scenarios/${fileId}`);
        let url = await ref.getDownloadURL();
        return axios.get(url);
    }

    listScenario() {
        let storageRef = storage.ref(`scenarios`);
        return storageRef
            .listAll()
            .then((res) => {
                return Promise.all(res.items.map((item) => item.getMetadata()));
            })
            .catch((err) => {
                console.log(err);
                return null;
            });
    }

    updateListJSON() {
        this.listScenario().then((x) => {
            if (!x) return;
            console.log(x);
            let obj = {
                scenarios: x
                    .filter((s) => s.name != "list.json")
                    .map((s) => {
                        return {
                            filename: s.name,
                            title: s.customMetadata ? s.customMetadata.title : "err",
                            cover: s.customMetadata ? s.customMetadata.cover : "",
                            chapterIndex: s.customMetadata
                                ? s.customMetadata.chapterIndex
                                : "0",
                            chapterTitle: s.customMetadata
                                ? s.customMetadata.chapterTitle
                                : "",
                            chapterSubTitle: s.customMetadata
                                ? s.customMetadata.chapterSubTitle
                                    ? s.customMetadata.chapterSubTitle
                                    : ""
                                : "",
                            chapterLink: s.customMetadata
                                ? s.customMetadata.chapterLink
                                    ? s.customMetadata.chapterLink
                                    : ""
                                : "",
                            chapterThumb: s.customMetadata
                                ? s.customMetadata.chapterThumb
                                    ? s.customMetadata.chapterThumb
                                    : ""
                                : "",
                            isActive: s.customMetadata
                                ? s.customMetadata.isActive == "true"
                                : false,
                            isDevActive: s.customMetadata
                                ? s.customMetadata.isDevActive == "true"
                                : false,
                            isTool: s.customMetadata
                                ? s.customMetadata.isTool == "true"
                                : false,
                            isOnlyImage: s.customMetadata
                                ? s.customMetadata.isOnlyImage == "true"
                                : false,
                            explain: s.customMetadata ? s.customMetadata.explain : "",
                            usedMentoroid:
                                s.customMetadata && s.customMetadata.usedMentoroid
                                    ? s.customMetadata.usedMentroid
                                    : {
                                        ena: true,
                                        rabic: false,
                                    },
                        };
                    }),
            };
            let storageRef = storage.ref();
            let ref = storageRef.child(`scenarios/list.json`);
            ref
                .putString(JSON.stringify(obj))
                .then(async (snapshot) => {
                    console.log("update list.json");
                })
                .catch((err) => {
                    console.log(err);
                });
        });
    }
}

class StorageDefinitionStore {
    async save(dataStr: string, metadata: any) {
        let storageRef = storage.ref();
        let ref = storageRef.child(`definitions/0`);
        return ref.putString(dataStr).then(
            async (snapshot) => {
                await ref.updateMetadata(metadata);
                return snapshot;
            },
            (err) => {
                console.log(err);
            }
        );
    }

    async load() {
        let storageRef = storage.ref();
        let ref = storageRef.child(`definitions/0`);
        let url = await ref.getDownloadURL();
        return axios.get(url);
    }
}

class ImageStorage {
    async putFile(fileId: any, image: any, folder: string) {
        let storageRef = storage.ref();
        let ref = storageRef.child(`${folder}/${fileId}.png`);
        return ref.put(image);
    }

    async getFileUrl(fileName: any, folder: string) {
        let storageRef = storage.ref();
        let ref = storageRef.child(`${folder}/${fileName}`);
        let url = await ref.getDownloadURL();
        return url;
    }

    async getAllFile(folder: string) {
        let storageRef = storage.ref();
        return storageRef.child(`${folder}/`).listAll();
    }

    async delFile(fileName: string, folder: string) {
        let storageRef = storage.ref();
        return storageRef.child(`${folder}/${fileName}`).delete();
    }
}

export const scenarioStore = new ScenarioStore();
export const imageStorage = new ImageStorage();
export const definitionStore = new StorageDefinitionStore();
