import React from 'react'
import Store from '../../Store/CommonStore'
import {Page} from "./Page";

export const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
const grid = 8;


export const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "#DDF" : "#F5F7FB",
    position: "relative",
    // styles we need to apply on draggables
    ...draggableStyle
});
export const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "lightblue" : "white",
    padding: grid
});

class DataUnitItem extends React.Component<any, {}> {

}

export default Store.withStore(Page)
