import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyDXedKG4ts5rwmpygZUnzruYYGwxdHKyxg",
    authDomain: "togo-a2894.firebaseapp.com",
    databaseURL: "https://togo-a2894.firebaseio.com",
    projectId: "togo-a2894",
    storageBucket: "togo-a2894.appspot.com",
    messagingSenderId: "1069041587578",
    appId: "1:1069041587578:web:833b623562f328cfc832c3"
};
export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore();
