import React from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {ImageComponent} from "./ImageComponent";
import {IfEndComponent} from "./IfEndComponent";
import {ForEndComponent} from "./ForEndComponent";
import {MotionComponent} from "./MotionComponent";
import {DelayComponent} from "./DelayComponent";
import {getItemStyle, getListStyle, reorder} from "./ScenarioPage";
import {Item} from "./Items";
import {
    ChoiceComponent,
    ForStartComponent,
    IfStartComponent,
    InputComponent,
    SlideComponent,
    TextComponent
} from "./components";
import {DeleteButton} from "../../component/DeleteButton";
import {DeleteIcon} from "../../component/DeleteIcon";

export class _ScenarioContentBuilder extends React.Component<any, {}> {
    constructor(props: any) {
        super(props);
        this.state = {};
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd(result: any) {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.props.scenario,
            result.source.index,
            result.destination.index
        );

        this.props.updateScenario(items)
    }

    onChangeItem(idx: number, item: any) {
        const sc = this.props.scenario;
        sc[idx] = item;
        this.props.updateScenario([...sc])
    }

    getItemColor(type: string) {
        if (type === "image") return "#b3e5fc"
        if (type === "text") return "#b2ebf2"
        if (type === "motion") return "#b2dfdb"
        if (type === "input") return "#f8bbd0"
        if (type === "choice") return "#ffcdd2"
        if (type === "if_start") return "#f0f4c3"
        if (type === "if_end") return "#f0f4c3"
        if (type === "for_start") return "#f0f4c3"
        if (type === "for_end") return "#f0f4c3"
        if (type === "delay") return "#c6f0c0"
        if (type === "slide") return "#aa9922"
        return "#333"
    }

    render() {
        const store = this.props.store
        const lang = this.props.lang
        let indent = 0;
        return (
            <div style={{
                height: "100%",
                overflowY: "scroll",
                backgroundColor: "white"
            }}>
                <div className={`p-2 border-solid font-bold border-0 border-b border-gray-200 h-12`}>
                    <div className={`p-2`}>シナリオ内容(Sum: {store.get('one') + store.get('two')})</div>
                </div>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {
                                    this.props.scenario.map((item: Item, index: number) => {
                                        let idt = indent
                                        if (item.type === "if_start" || item.type === "for_start") ++indent
                                        else if (item.type === "if_end" || item.type === "for_end") {
                                            --indent
                                            idt = indent
                                        }
                                        return <Draggable key={`item_${index}`} draggableId={`${index}`}
                                                          index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    className={`!bg-light-blue`}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        ),
                                                        // marginLeft: idt * 20,
                                                        borderLeft: "solid 5px",
                                                        borderRadius: "6px",
                                                        borderColor: this.getItemColor(item.type)
                                                    }}
                                                >
                                                    <div style={{
                                                        color: "#222",
                                                        backgroundColor: this.getItemColor(item.type),
                                                        borderRadius: "5px",
                                                        margin: "-10px",
                                                        marginBottom: "10px",
                                                        paddingLeft: "10px",
                                                        paddingRight: "10px",
                                                        width: "min-content"
                                                    }}>
                                                        {item.type}
                                                    </div>
                                                    <div style={{marginTop: "10px"}}>
                                                        {
                                                            item.type === "text" ?
                                                                <TextComponent key={index} item={item} lang={lang}
                                                                               onUpdate={(s) => {
                                                                                   this.onChangeItem(index, s)
                                                                               }}
                                                                               enecolorGroupData={this.props.enecolorGroupData}/> :
                                                                item.type === "image" ?
                                                                    <ImageComponent key={index} item={item}/> :
                                                                    item.type === "input" ?
                                                                        <InputComponent key={index} item={item}
                                                                                        onUpdate={(s) => {
                                                                                            this.onChangeItem(index, s)
                                                                                        }}/> :
                                                                        item.type === "choice" ?
                                                                            <ChoiceComponent key={index} item={item}
                                                                                             onUpdate={(s) => {
                                                                                                 this.onChangeItem(index, s)
                                                                                             }}/> :
                                                                            item.type === "if_start" ?
                                                                                <IfStartComponent key={index}
                                                                                                  item={item}
                                                                                                  onUpdate={(s) => {
                                                                                                      this.onChangeItem(index, s)
                                                                                                  }}/> :
                                                                                item.type === "if_end" ?
                                                                                    <IfEndComponent key={index}
                                                                                                    item={item}/> :
                                                                                    item.type === "for_start" ?
                                                                                        <ForStartComponent key={index}
                                                                                                           item={item}
                                                                                                           onUpdate={(s) => {
                                                                                                               this.onChangeItem(index, s)
                                                                                                           }}/> :
                                                                                        item.type === "for_end" ?
                                                                                            <ForEndComponent key={index}
                                                                                                             item={item}/> :
                                                                                            item.type === "motion" ?
                                                                                                <MotionComponent
                                                                                                    key={index}
                                                                                                    item={item}
                                                                                                    onUpdate={(s) => {
                                                                                                        this.onChangeItem(index, s)
                                                                                                    }}/> :
                                                                                                item.type === "delay" ?
                                                                                                    <DelayComponent
                                                                                                        key={index}
                                                                                                        item={item}
                                                                                                        onUpdate={(s) => {
                                                                                                            this.onChangeItem(index, s)
                                                                                                        }}/> :
                                                                                                    item.type === "slide" ?
                                                                                                        <SlideComponent
                                                                                                            key={index}
                                                                                                            item={item}
                                                                                                            onUpdate={(s) => {
                                                                                                                this.onChangeItem(index, s)
                                                                                                            }}/> :
                                                                                                        <div>invalid</div>
                                                        }
                                                    </div>
                                                    <div style={{
                                                        position: "absolute", right: 0, top: 0
                                                    }}>
                                                        {/*<Button variant="contained"*/}
                                                        {/*        onClick={(e) => this.props.onDeleteItem(index, item)}*/}
                                                        {/*        style={{*/}
                                                        {/*            fontSize: "5px"*/}
                                                        {/*        }}>削除</Button>*/}
                                                        <DeleteIcon
                                                            icon={'images/icons/iconX.svg'}
                                                            onClick={(e) => this.props.onDeleteItem(index, item)}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    })
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    }
}
