import React from 'react'
import ScenarioPage from './ScenarioPage/ScenarioPage'
import DataPage from './DataPage/DataPage'
import {
    AppBar,
    CssBaseline,
    Drawer, Grid,
    List,
    ListItem,
    ListItemIcon, ListItemText,
    Tab,
    Tabs,
    Toolbar,
    Typography
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import BannerPopup from './BannerPopupPage/BannerPopup';

// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        paddingTop: "60px",
        "& .MuiListItemIcon-root": {
            minWidth: "35px"
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,

        "& .MuiTypography-root": {
            fontFamily: 'Outfit',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: 24,
            letterSpacing: 0.15,
            textTransform: 'capitalize',
        }
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
        marginTop: "20px"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

export default () => {
    const [value, setValue] = React.useState(2)
    const classes = useStyles();
    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };
    const menu = ["データ構造", "シナリオ", "両方", "バナー&ポップアップ"]
    const icons = ["folderOpen", "newspaper", "collection", "documentText"]
    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar position="fixed" className={`${classes.appBar} rounded-bl-[30px] !bg-dark-blue pl-4`}>
                <Toolbar>
                    <Typography variant="h6" noWrap>
                        Mentoroid
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Toolbar/>
                <div className={classes.drawerContainer}>
                    <List>
                        {menu.map((text, index) => (
                            <ListItem className={`${index === value ? `!bg-light-blue-active` : ''}`} style={{}} button
                                      key={text} onClick={(e: any) => handleChange(e, index)}>
                                <div
                                    className={`${index === value ? `h-full w-1 bg-dark-blue absolute left-0 rounded-lg` : ''}`}></div>
                                <ListItemIcon>
                                    <img
                                        className={`w-6 h-6`}
                                        src={"images/icons/" + icons[index] + (value === index ? "Active" : '') + ".svg"}
                                        alt=""/>
                                </ListItemIcon>
                                <ListItemText primary={text}/>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Drawer>
            <main className={`${classes.content} bg-light-blue`}>
                {
                    value === 0 &&
                    // <Grid item xs={value === 2 ? 6 : 12}>
                    <div className={'overflow-auto'}>
                        <DataPage/>
                    </div>
                    // </Grid>
                }
                {
                    value === 1 &&
                    // <Grid item xs={value === 2 ? 6 : 12}>
                    <div className={'bg-light-blue p-2'}>
                        <ScenarioPage/>
                    </div>
                    // </Grid>
                }
                {
                    value === 2 &&
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <div className={'overflow-auto'}>
                                <DataPage/>
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div className={`bg-light-blue p-4 border-solid !border-l border-0 border-gray-300`}>
                                <ScenarioPage/>
                            </div>
                        </Grid>
                    </Grid>
                    // <Grid item xs={value === 2 ? 6 : 12}>
                    // </Grid>
                }
                {value === 3 && <div className={`basis-2/3`}>
                    <BannerPopup/>
                </div>}

            </main>
        </div>
        // <div className={classes.root}>
        //     <AppBar>
        //         <Tabs value={value} onChange={handleChange}>

        //         </Tabs>
        //     </AppBar>
        //     <div className={'flex flex-row gap-1 justify-center w-full'}>
        //         {
        //             value === 0 || value === 2 ?
        //                 // <Grid item xs={value === 2 ? 6 : 12}>
        //                 <div className={'basis-1/3 flex flex-1 overflow-auto'}>
        //                     <DataPage />
        //                 </div>
        //                 // </Grid>
        //                 : null
        //         }
        //         {
        //             value === 1 || value === 2 ?
        //                 // <Grid item xs={value === 2 ? 6 : 12}>
        //                 <div className={'basis-2/3'}>
        //                     <ScenarioPage />
        //                 </div>
        //                 // </Grid>
        //                 : null
        //         }
        //         {value === 3 && <div className={`basis-2/3`}>
        //             <BannerPopup />
        //         </div>}
        //
        //     </div>
        // </div>
    )
}
