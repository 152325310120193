import Store from "../../Store/CommonStore";
import {_ForStartComponent} from "./_ForStartComponent";
import {_TextComponent} from "./_TextComponent";
import {_ChoiceComponent} from "./_ChoiceComponent";
import {_StorageSelectComponent} from "./_StorageSelectComponent";
import {_IfStartComponent} from "./_IfStartComponent";
import {_InputComponent} from "./_InputComponent";
import {_SlideComponent} from "./_SlideComponent";

export const TextComponent = Store.withStore(_TextComponent)
export const SlideComponent = Store.withStore(_SlideComponent)
export const InputComponent = Store.withStore(_InputComponent)
export const ChoiceComponent = Store.withStore(_ChoiceComponent)
export const IfStartComponent = Store.withStore(_IfStartComponent)
export const ForStartComponent = Store.withStore(_ForStartComponent)
export const StorageSelectComponent = Store.withStore(_StorageSelectComponent)
