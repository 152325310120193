import React from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {DataUnit} from "../../model/DataStructure";
import {FormControl, MenuItem, Select, TextField} from "@material-ui/core";
import {getItemStyle, getListStyle, reorder} from "./DataPage";
import {DeleteIcon} from "../../component/DeleteIcon";

export class DraggableListItem extends React.Component<any, {}> {
    constructor(props: any) {
        super(props);
        this.state = {};
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd(result: any) {
        if (!result.destination) {
            return;
        }

        this.props.storage.data = reorder(
            this.props.storage.data,
            result.source.index,
            result.destination.index
        );

        this.props.updateStorage(this.props.storage)
    }

    render() {

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {this.props.storage.data.map((item: DataUnit, index: number) => (
                                <Draggable key={`item_${index}`} draggableId={`${index}`} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            className={'rounded'}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <div className={'flex items-center flex-wrap gap-1'}>
                                                <FormControl variant="outlined" margin="dense">
                                                    <Select
                                                        value={item.type}
                                                        onChange={(e) => {
                                                            item.type = e.target.value as string
                                                            this.props.updateStorage(this.props.storage)
                                                        }}
                                                    >
                                                        <MenuItem value="string">文字列</MenuItem>
                                                        <MenuItem value="number">数値</MenuItem>
                                                        <MenuItem value="image">画像</MenuItem>
                                                        <MenuItem value="array">配列</MenuItem>
                                                        <MenuItem value="storage">データ構造</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <TextField
                                                    label="変数名"
                                                    value={item.name}
                                                    onChange={(e) => {
                                                        item.name = e.target.value as string
                                                        this.props.updateStorage(this.props.storage)
                                                    }}
                                                    size="small"
                                                    margin="none"
                                                    variant="outlined"
                                                    className={`!mt-1`}
                                                />
                                            </div>
                                            <div style={{
                                                position: "absolute", right: 2, top: 2
                                            }}>
                                                <DeleteIcon
                                                    icon={'images/icons/iconX.svg'}
                                                    onClick={(e) => this.props.onDeleteItem(index, item)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}
