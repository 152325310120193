import {Button, Modal} from '@material-ui/core';
import {ExclamationCircleIcon} from '@heroicons/react/24/solid';
import React from 'react';

const DeleteModal = ({title, show, setShow, onYes, onNo}) => {
    return (
        <Modal
            open={show}
            onClose={() => setShow(false)}
        >
            <div
                className="text-center bg-white w-1/2 mx-auto mt-10 pb-10 rounded-xl">
                <ExclamationCircleIcon
                    className="mx-auto mb-3 mt-4 h-14 w-14 text-yellow-300"/>
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                    {/*`{data.title}` ({data?.filename}) を削除しますか？*/}
                    {title}
                </h3>
                <div className="flex justify-center gap-4">

                    <Button
                        onClick={onYes}
                    >
                        <span className={'text-red-700'}>削除</span>
                    </Button>

                    <Button
                        variant={'outlined'}
                        onClick={onNo}
                    >
                        キャンセル
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteModal;
