import {ItemComponent} from "./ItemComponent";
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import React from "react";
import {GenUnits} from "./ScenarioPage";
import {ChoiceItem} from "./Items";

export class _ChoiceComponent extends ItemComponent {
    constructor(props) {
        super(props)
    }

    render() {
        const store = this.props.store
        const units = GenUnits(store)
        const item = this.props.item as ChoiceItem

        return <div>
            <FormControl variant="outlined" margin='dense'>
                <Select
                    value={JSON.stringify({
                        targetId: item.target_id,
                        input_data: item.input_data
                    })}
                    onChange={(e) => {
                        const targetId = JSON.parse(e.target.value as string).targetId;
                        const input_data = JSON.parse(e.target.value as string).input_data;
                        item.target_id = targetId;
                        item.input_data = input_data;
                        this.props.onUpdate(item)
                    }}
                >
                    {
                        units.map((unit, ui) => {
                            return <MenuItem key={ui}
                                             value={JSON.stringify({
                                                     targetId: unit.unit.id,
                                                     input_data: `${unit.storage.name}:${unit.unit.name}`
                                                 }
                                             )}>{unit.storage.name}:{unit.unit.name}</MenuItem>//TODO: choice input
                        })
                    }
                </Select>
            </FormControl>
            <div>
                <div> --- 選択肢 ---</div>
                {
                    item.choices_targets.map((choice, ci) => {
                        return <div key={ci}>
                            <FormControl variant="outlined" margin='dense'>
                                <InputLabel>参照</InputLabel>
                                <Select
                                    value={JSON.stringify({
                                        targetId: choice.target_id,
                                        input_data: choice.input_data
                                    })}
                                    onChange={(e) => {
                                        const targetId = JSON.parse(e.target.value as string).targetId;
                                        const input_data = JSON.parse(e.target.value as string).input_data;
                                        choice.target_id = targetId;
                                        choice.input_data = input_data;
                                        this.props.onUpdate(item)
                                    }}
                                >
                                    <MenuItem value="">固定値</MenuItem>
                                    {
                                        units.map((unit, ui) => {
                                            return <MenuItem key={ui}
                                                             value={JSON.stringify({
                                                                     targetId: unit.unit.id,
                                                                     input_data: `${unit.storage.name}:${unit.unit.name}`
                                                                 }
                                                             )}>{unit.storage.name}:{unit.unit.name}</MenuItem>
                                        })
                                    }
                                </Select>
                                {
                                    choice.target_id == "" ?
                                        <TextField
                                            key="tf"
                                            id="outlined-name"
                                            label="固定値"
                                            value={choice.text}
                                            onChange={(e) => {
                                                choice.text = e.target.value as string
                                                this.props.onUpdate(item);
                                            }}
                                            margin="none"
                                            variant="outlined"
                                            size="small"
                                        />
                                        : null
                                }
                            </FormControl>
                        </div>
                    })
                }
                < Button variant="contained" onClick={(e) => {
                    item.choices_targets.push({target_id: "", input_data: "", text: ""})
                    this.props.onUpdate(item)
                }} style={{
                    fontSize: "5px"
                }}>追加</Button>
            </div>
        </div>
    }
}
