import {ItemComponent} from "./ItemComponent";
import {FormControl, FormHelperText, MenuItem, Select, TextField} from "@material-ui/core";
import React from "react";
import {Enecolor16Color, Enecolor4Color, EnecolorGroupData, GenUnits, getArgs, ScenarioContext} from "./ScenarioPage";
import {TextItem} from "./Items";

export class _TextComponent extends ItemComponent {
    constructor(props) {
        super(props)
    }

    public getStr() {
        const item = this.props.item as TextItem
        return this.props.lang == "english" ?
            item.message.english
            : item.message.japanese
    }

    render() {
        const context = this.props.context as ScenarioContext
        const item = this.props.item as TextItem
        const str = this.getStr()
        const store = this.props.store
        const units = GenUnits(store)
        if (item.mentroid_name == null) item.mentroid_name = "エナ"
        return <div>
            <div className={`mb-3`}>
                声：<Select
                value={item.mentroid_name}
                onChange={(e) => {
                    item.mentroid_name = e.target.value as string
                    this.props.onUpdate(item)
                }}
            >
                {
                    ["エナ", "ラビック"].map((s, ui) => {
                        return <MenuItem key={ui} value={s}>{s}</MenuItem>
                    })
                }
            </Select>
            </div>
            <TextField
                size="small"
                id="outlined-name"
                label="出力テキスト"
                value={item.message.japanese}
                onChange={(e) => {
                    const s = e.target.value as string
                    item.message.japanese = s
                    item.message.english = s
                    const newArgs = getArgs(s);
                    newArgs.forEach(a => {
                        const p = item.args.find(b => a.name == b.name);
                        if (p) {
                            a.target_id = p.target_id;
                            a.groups = p.groups;
                            a.rank = p.rank;
                        }
                    })
                    item.args = newArgs;
                    this.props.onUpdate(item);
                }}
                style={{width: "100%"}}
                // multiline={true}
                // margin="none"
                variant="outlined"
            />
            <div key="type_select">
                <FormControl variant="outlined" margin='dense'>
                    <FormHelperText>種別</FormHelperText>
                    <Select
                        value={item.output_type}
                        onChange={(e) => {
                            const t = e.target.value as string
                            item.output_type = t
                            this.props.onUpdate(item)
                        }}
                    >
                        <MenuItem value={"simple"}>シンプル</MenuItem>
                        <MenuItem value={"enecolor_4"}>エネカラー４</MenuItem>
                        <MenuItem value={"enecolor_16"}>エネカラー１６</MenuItem>
                        {
                            units.map((unit, ui) => {
                                return <MenuItem key={`unit_#{ui}`}
                                                 value={unit.unit.id}>{unit.storage.name}:{unit.unit.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </div>
            <div>
                {
                    item.output_type == "simple" ?
                        item.args ? item.args.map((arg, argi) => {
                            return <div key={argi}>
                                <FormControl variant="outlined" margin='dense'>
                                    <FormHelperText>{arg.name}</FormHelperText>
                                    <Select
                                        label="引数"
                                        value={arg.target_id}
                                        onChange={(e) => {
                                            item.args[argi].target_id = e.target.value as string
                                            this.props.onUpdate(item)
                                        }}
                                    >
                                        <MenuItem value={""}>未選択</MenuItem>
                                        {
                                            units.map((unit, ui) => {
                                                return <MenuItem key={`unit_#{ui}`}
                                                                 value={unit.unit.id}>{unit.storage.name}:{unit.unit.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        }) : null
                        : item.output_type == "enecolor_4" || item.output_type == "enecolor_16" ?
                            item.args ? item.args.map((arg, argi) => {
                                    const isEnecolor4 = item.output_type == "enecolor_4";
                                    const isEnecolor16 = item.output_type == "enecolor_16"
                                    const colors = isEnecolor4 ? Enecolor4Color : Enecolor16Color;
                                    let egd = this.props.enecolorGroupData as EnecolorGroupData[]
                                    if (isEnecolor4) {
                                        egd = egd.filter(d => (d.messages.length == 4))
                                    }
                                    if (isEnecolor16) {
                                        egd = egd.filter(d => (d.messages.length == 16))
                                    }
                                    if (isEnecolor4 && (!arg.groups || arg.groups.length != 4)) arg.groups = [...colors]
                                    if (isEnecolor16 && (!arg.groups || arg.groups.length != 16)) arg.groups = [...colors]
                                    return <div key={argi}>
                                        <div>
                                            <hr/>
                                            <div>
                                                {arg.name}の内容
                                            </div>
                                            <div>
                                                <FormControl variant="outlined">
                                                    <FormHelperText>順位</FormHelperText>
                                                    <Select
                                                        value={arg.rank}
                                                        type="number"
                                                        onChange={(e) => {
                                                            arg.rank = e.target.value as number
                                                            this.props.onUpdate(item)
                                                        }}
                                                    >
                                                        {
                                                            colors.map((x, xi) => {
                                                                return <MenuItem key={xi + 1}
                                                                                 value={xi + 1}>{xi + 1}位</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div>
                                                DBから取得：
                                                <div>
                                                    <FormControl variant="outlined" margin='dense'>
                                                        <Select
                                                            value={-1}
                                                            onChange={(e) => {
                                                                const idx = e.target.value as number
                                                                if (idx == -1) return
                                                                const v = egd[idx];
                                                                let lis = [] as string[];
                                                                if (isEnecolor4) {
                                                                    arg.groups = [...Enecolor4Color];
                                                                    lis = Enecolor4Color;
                                                                }
                                                                if (isEnecolor16) {
                                                                    arg.groups = [...Enecolor16Color]
                                                                    lis = Enecolor16Color
                                                                }
                                                                lis.forEach((name, ei) => {
                                                                    if (arg.groups) {
                                                                        arg.groups[ei] = v.messages[ei]
                                                                    }
                                                                })
                                                                this.props.onUpdate(item)
                                                            }}
                                                        >
                                                            <MenuItem key={-1} value={-1}>コピーしてくる</MenuItem>
                                                            {
                                                                egd.map((group, ui) => {
                                                                    return <MenuItem key={ui}
                                                                                     value={ui}>{group.category}_{group.comment}</MenuItem>
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                {
                                                    arg.groups ? arg.groups.map((c, ci) => {
                                                            return <div key={"color_" + ci}>
                                                                <FormControl variant="outlined" style={{width: "100%"}}>
                                                                    <TextField

                                                                        label={colors[ci]}
                                                                        value={arg.groups && arg.groups.length >= ci ? arg.groups[ci] : ""}
                                                                        onChange={(e) => {
                                                                            let s = e.target.value
                                                                            let group = item.args[argi].groups
                                                                            if (group && group.length >= ci) {
                                                                                group[ci] = s
                                                                            }
                                                                            this.props.onUpdate(item)
                                                                        }
                                                                        }
                                                                        fullWidth={true}
                                                                        multiline={true}
                                                                        margin="none"
                                                                        variant="outlined"
                                                                        size="small"
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        })
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                })
                                : null
                            : item.output_type == "enecolor_16" ?
                                item.args ? item.args.map((arg, argi) => {
                                        return <div key={argi}>
                                        </div>
                                    })
                                    : null
                                : null
                }
            </div>
        </div>
    }
}
