import {getArgs, ItemArg} from "./ScenarioPage";
import Store from "../../Store/CommonStore";
import {_ScenarioContentBuilder} from "./_ScenarioContentBuilder";

export class Item {
    type: string = ""
}

export class TextItem extends Item {
    message_id: number = 0
    message: { japanese: string, english: string } = {japanese: "", english: ""}
    args: ItemArg[] = []
    output_type: string = ""
    mentroid_name: string = "エナ"

    constructor(_type: string, langItem: any) {
        super()
        this.type = _type;
        this.message_id = langItem.id
        this.message.japanese = langItem.japanese
        this.message.english = langItem.english
        this.args = getArgs(langItem.japanese)
        this.output_type = "simple"
        this.mentroid_name = "エナ"
    }
}

export class ImageItem extends Item {
    url: string = ""
    filename: string = ""

    constructor(name: string, url: string) {
        super()
        this.type = "image"
        this.filename = name
        this.url = url
    }
}

export class InputItem extends Item { //TODO
    target_id: string = ""
    input_data: string = ""
    input_type: "string" | "number" = "string"
    is_enecolor_bar: boolean = false
    minValue: number = 0.0
    maxValue: number = 1.0

    constructor(input_type: "string" | "number") {
        super()
        this.type = "input"
        this.is_enecolor_bar = false;
        this.input_type = input_type
    }
}

export class SlideItem extends Item {
    target_id: string = ""
    input_type: string = "slide"
    page_no: number = 0
    recent_object_id: string = ""
    json: any = {}
    error_message: string = ""

    constructor() {
        super()
        this.type = "slide"
        this.input_type = "slide"
    }
}

export class ChoiceItem extends Item {
    target_id: string = ""
    input_data: string = ""
    input_type: "string" | "number" = "string"
    choices_targets: { target_id: string, input_data: string, text: string }[] = []

    constructor() {
        super()
        this.type = "choice"
    }
}

export class IfStartItem extends Item {
    condition: string = ""
    args: ItemArg[] = []

    constructor() {
        super()
        this.type = "if_start"
        this.args = getArgs(this.condition);
    }
}

export class IfEndItem extends Item {
    constructor() {
        super()
        this.type = "if_end"
    }
}

export class ForStartItem extends Item {
    condition: string = ""
    counter: ItemArg = {name: "i", target_id: ""};
    args: ItemArg[] = []

    constructor() {
        super()
        this.type = "for_start"
        this.args = getArgs(this.condition);
    }
}

export class ForEndItem extends Item {
    constructor() {
        super()
        this.type = "for_end"
    }
}

export class MotionItem extends Item {
    mentroid_name: string = "エナ"
    motion_type: string = "そのまま"
    location: string = "そのまま"
    flip: string = "そのまま"
    show: string = "そのまま"

    constructor() {
        super()
        this.type = "motion"
    }
}

export class DelayItem extends Item {
    seconds: number = 10

    constructor() {
        super()
        this.type = "delay"
    }
}

export const ScenarioContentBuilder = Store.withStore(_ScenarioContentBuilder)
